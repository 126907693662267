<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>tools-hammer-2</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.652,11.47,3.515,22.607a1.5,1.5,0,0,1-2.122-2.122L12.53,9.348"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.773,5.106l1.061-1.061a1.5,1.5,0,1,1,2.121,2.121L18.894,7.227"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="16.915"
      y1="13.733"
      x2="10.267"
      y2="7.085"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="14.51"
      y1="2.843"
      x2="21.157"
      y2="9.49"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M19.425,15.182A1.5,1.5,0,1,1,17.3,13.061l3.182-3.182A1.5,1.5,0,1,1,22.607,12Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.939,6.7A1.5,1.5,0,1,1,8.818,4.575L12,1.393a1.5,1.5,0,1,1,2.121,2.122Z"
    />
  </svg>
</template>
